
import { Chart } from '../components/helpers';
import {createBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import { Col } from '@commonsku/styles';
import { useSelector } from 'react-redux';

function SalesOrdersInProductionChart(props) {
    return(
        <WidgetCardBox colSizes={{xs: 12, sm:12, lg: 6}}
        components={{
        Title: <WidgetTitle startText={'Sales Orders in Production'} />
        }}>
            <Col xs >
                <Chart
                type="Bar"
                height="340px"
                width="100%"
                isExportable={false}
                data={props.chart_data}
                onChartReady={(chart, data) => createBarChart(chart, {
                data,
                onClickColumn: props.onClickChartColumn ? props.onClickChartColumn : null,
                isExportable:false,
                cursorLineX:false,
                cursorLineY:false,
                isLegendCheckbox:true,
                formatNumber:'#a',
                chartColorsAnalytics:['#B8C4CB','#FFD302','#DB0057'],
                toggleSalesLegend:true,
                xAxes: [{
                    cursorTooltipEnabled: false,
                    category: 'month',
                    title: 'Month',
                    showGrid: false,
                    showAxis: true,
                    showTitle: true,
                    showLabel: true,
                    cellStartLocation:0.1,
                    cellEndLocation:0.7,
                    disableGridTemplate:true
                }],
                yAxes: [{
                    min: 0, 
                    cursorTooltipEnabled:false,
                    showGrid: false,
                    showAxis: false,
                    showTitle: false,
                    renderOutside:true,
                    showLabel:true,
                    disableGridTemplate:true
                }],
                series: [{
                    categoryX: 'month',
                    valueY: 'total',
                    name:'Previous Year',
                    tooltipText: `[#fff font-size: 15px]{month} {year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#B8C4CB', // gray fill
                    stroke : '#B8C4CB'

                },
                {
                    categoryX: 'month',
                    valueY: 'total_next_year' ,
                    name: 'This Year',
                    tooltipText: `[#fff font-size: 15px]{month} {next_year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#FFD302', // yellow fill
                    stroke : '#FFD302'
                }
                ],
                lineSeries:  [{
                    categoryX: 'month',
                    valueY: 'sales_orders_target',
                    name: "Target",
                    tooltipText: `[#fff font-size: 15px]{categoryX} {target_year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                    strokeWidth: 3,
                    stroke:'#DB0057',
                    fillPropertyOpacity: 'opacity',
                    }]
                })}
                >

                </Chart>
            </Col>
        </WidgetCardBox>
    );
}

export default SalesOrdersInProductionChart;
